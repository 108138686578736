<template>
  <main>
    <AdWrapper :ad="{ slotId: 'dsk_top_ad' }" />
    <AdWrapper :ad="{ slotId: 'mob_top_ad' }" />
    <div class="bg-grey-200 py-10">
      <AdWrapper :ad="{ slotId: 'dsk_responsive_1' }" />
      <AdWrapper :ad="{ slotId: 'mob_responsive_1' }" />
    </div>
    <div class="lg:-mx-10">
      <div class="lg:mx-10">
        <LazyNewsRibbonContainer />
      </div>
      <Aptoma />
    </div>
  </main>
</template>

<script setup lang="ts">
/**
 * Inject metatags (OG, Schema.org)
 */
const { frontpageMeta } = useAllerMetaData();
frontpageMeta('SE og HØR giver altid dig de nyeste underholdningshistorier om de kendte og kongelige danskere, samt Hollywoods største stjerner. Læs alle nyhederne.');

// Force native false for static pages.
useState('native').value = false;

onMounted(() => {
  const { GTMPush } = useAllerGTM();
  GTMPush({
    pageTitle: 'Forside',
    pageType: 'front_page',
  });
});
</script>
